<template>
  <div class="container__img__aboutUs__containers__box4">
    <div class="container-fluid size d-flex mobile-aboutus">
      <div
        class="col-md-12 col-lg-12 col-xl-12 container__img__aboutUs__containers__box2 pl-5 pb-0 pt-5"
      >
        <h1 class="styles__title text-start m-auto text-center pt-5">
          PREGUNTAS FRECUENTES
        </h1>

        <p class="styles__p m-auto text-center">
          Bienvenidos a la sección de preguntas frecuentes! Aquí responderemos
          las preguntas que nos llegan mas seguido a nuestro correo de servicio
          al cliente.
        </p>

        <vsa-list class="mt-5">
          <vsa-item>
            <vsa-heading class="w-100">¿Qué es maquillate.com?</vsa-heading>
            <VsaIcon>
              <i class="fas fa-plus"></i>
            </VsaIcon>
            <vsa-content>
              Somos una plataforma innovadora de educación sobre el maquillaje.
              Nuestra misión es brindar oportunidades de aprendizaje sobre
              nuevas tendencias, productos y técnicas de vanguardia. Con un
              equipo diverso de instructores calificados provenientes de
              distintos paises, aseguramos una experiencia de aprendizaje rica e
              inclusiva para nuestros usuarios. Ya sea que esté utilizando una
              computadora, tableta o teléfono inteligente, se puede acceder a
              nuestra plataforma desde cualquier dispositivo, lo que hace que el
              aprendizaje sea conveniente y agradable.
            </vsa-content>
          </vsa-item>

          <vsa-item>
            <vsa-heading class="w-100"
              >¿Tengo que pagar para poder usar la plataforma?</vsa-heading
            >
            <VsaIcon>
              <i class="fas fa-plus"></i>
            </VsaIcon>
            <vsa-content>
              Nuestra plataforma es de acceso gratuito, pero para aquellos que
              buscan mejorar su conocimiento del maquillaje, ofrecemos cursos
              premium que profundizan en el arte de la aplicación del
              maquillaje. Estos cursos brindan explicaciones detalladas y
              técnicas avanzadas para ayudarlo a dominar el arte del maquillaje.
            </vsa-content>
          </vsa-item>

          <vsa-item>
            <vsa-heading class="w-100">¿Qué es el auto maquillaje?</vsa-heading>
            <VsaIcon>
              <i class="fas fa-plus"></i>
            </VsaIcon>
            <vsa-content>
              El auto maquillaje es la habilidad de maquillarse a uno mismo sin
              la asistencia de un profesional. Implica aprender técnicas y
              utilizar productos para mejorar el aspecto personal.
            </vsa-content>
          </vsa-item>

          <vsa-item>
            <vsa-heading class="w-100"
              >¿Por dónde debo empezar si soy principiante en el auto
              maquillaje?</vsa-heading
            >
            <VsaIcon>
              <i class="fas fa-plus"></i>
            </VsaIcon>
            <vsa-content>
              Lo ideal es comenzar con una rutina básica que incluya limpieza
              facial, hidratación y protección solar. Luego, aprende a aplicar
              productos como base, corrector, sombras de ojos, delineador,
              máscara de pestañas y labial.
            </vsa-content>
          </vsa-item>

          <vsa-item>
            <vsa-heading class="w-100"
              >¿Qué productos de maquillaje son esenciales para
              principiantes?</vsa-heading
            >
            <VsaIcon>
              <i class="fas fa-plus"></i>
            </VsaIcon>
            <vsa-content>
              Los productos esenciales para principiantes son: base o BB cream,
              corrector, máscara de pestañas, rubor, labial y sombras de ojos
              neutrales.
            </vsa-content>
          </vsa-item>
        </vsa-list>
      </div>
    </div>
  </div>
</template>
<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon,
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

export default {
  name: 'FrequentQuestions',
  components: {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon,
  },
};
</script>

<style>
.vsa-list {
  --vsa-max-width: 720px;
  --vsa-min-width: 300px;
  --vsa-heading-padding: 1rem 1rem;
  --vsa-text-color: #000;
  --vsa-highlight-color: #b7227e !important;
  --vsa-bg-color: #fff;
  --vsa-border-color: none;
  --vsa-border-width: 1px;
  --vsa-border-style: solid;
  --vsa-border: var(--vsa-border-width) var(--vsa-border-style)
    var(--vsa-border-color);
  --vsa-content-padding: 1rem 1rem;
  --vsa-default-icon-size: 1;
  display: block;
  max-width: var(--vsa-max-width);
  min-width: var(--vsa-min-width);
  width: 100%;
  margin: 0;
  list-style: none;
  border: var(--vsa-border);
  color: var(--vsa-text-color);
  background-color: var(--vsa-bg-color);
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
  margin: auto;
}

.container__img__aboutUs__containers__box4
  .container__img__aboutUs__containers__box2
  button {
  max-width: 100%;
  padding: 4px;
  text-align: start;
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
  border-radius: 5px;
}
.container__img__aboutUs__containers__box4
  .container__img__aboutUs__containers__box2
  button
  i {
  font-size: 20px;
  margin-right: 15px;
}
.container__img__aboutUs__containers__box4
  .container__img__aboutUs__containers__box2
  button {
  padding: 10px;
}

.container__img__aboutUs__containers__box4
  .container__img__aboutUs__containers__box2
  button
  i {
  font-size: 20px;
  margin-right: 0px;
}

.vsa-item__trigger {
  transition: none;
}

.vsa-item__trigger__icon {
  transition: none;
}

.vsa-item__trigger__content {
  font-weight: 700;
  font-size: 1rem;
}
</style>
